<template>
  <page-content>
    <v-toolbar flat>
      <v-tabs v-model="selectedTab" center-active>
        <v-tab v-for="tab of tabs" :key="tab.name" :to="'#' + tab.id">{{
          tab.name
        }}</v-tab>
      </v-tabs>
    </v-toolbar>
    <v-text-field
      v-model="prefix"
      outlined
      :label="$t('codename.prefix')"
    ></v-text-field>
    <v-text-field
      outlined
      v-model="fromIndex"
      :label="$t('codename.fromIndex')"
    ></v-text-field>
    <v-text-field
      outlined
      v-model="toIndex"
      :label="$t('codename.toIndex')"
    ></v-text-field>
    <app-card-actions>
      <cancel-btn @click="$router.back()" />
      <apply-btn :loading="generateInProgress" default @click="onGenerateCodeNames" :text="$t('btn.codename.generate')"/>
      <destructive-btn :loading="deleteInProgress" @click="onDeleteCodeNames" :text="$t('btn.codename.delete')"/>
    </app-card-actions>
  </page-content>
</template>

<script>
import UserService from "@/services/user.service"
import SiteService from "@/services/site.service"
// import TasksIconBadge from "@/components/TasksIconBadge"
// import { AgGridVue } from "ag-grid-vue";
// import EventService from "@/services/event.service";
// import TaskService from "@/services/task.service";
// import { formatDistance, parseJSON } from "date-fns";
// import { defaultLocale } from '@/filters/date'
// import TaskIcon from '@/components/TaskIcon'

export default {
  name: "CodeNames",
  data() {
    return {
      generateInProgress: false,
      deleteInProgress: false,
      prefix: "A",
      fromIndex: 100,
      toIndex: 999,
      tabs: [],
      selectedTab: null,
      sortBy: ["Ostatnio dodani", "Nazwa", "Ostatnio zalogowani"],
      sortBySelected: null,
      filter: "",
      users: [],
      page: 1,
      pages: 2
    }
  },
  watch: {
    page() {
      this.refresh()
    },
    selectedTab() {
      this.refresh()
    }
  },
  filters: {},

  methods: {
    onGenerateCodeNames() {
      this.generateInProgress = true
      var codenames = []
      const from = parseInt(this.fromIndex)
      const to = parseInt(this.toIndex)
      Array(to - from + 1)
        .fill(from)
        .map((x, y) => {
          codenames.push({ create: `${this.prefix}${x + y}` })
        })
      SiteService.createCodenames(codenames).then(data => {
        this.generateInProgress = false
        this.$dialog.notify.info("Nowych kryptonimów: " + data.created, {
          timeout: 5000
        })
      })
    },
    onDeleteCodeNames() {
      this.deleteInProgress = true
      var codenames = []
      const from = parseInt(this.fromIndex)
      const to = parseInt(this.toIndex)
      Array(from + to + 1)
        .fill(from)
        .map((x, y) => {
          codenames.push({ delete: `${this.prefix}${x + y}` })
        })
      SiteService.createCodenames(codenames).then(data => {
        this.deleteInProgress = false
        this.$dialog.notify.info("Usunięto kryptonimów: " + data.deleted, {
          timeout: 5000
        })
      })
    },
    onNewUser() {
      // console.log("New user")
      // this.$router.push({ name: "New User", params: { user: {} } })
    },
    onEditUser(user) {
      this.$router.push({
        name: "Edit U  ser",
        params: { user: user, userId: user.id }
      })
    },
    onFilter() {
      this.refresh()
    },
    refresh() {
      const condition = this.tabs.find(t => t.id == this.selectedTab)?.condition
      UserService.fetchUsers(this.filter, condition, this.page).then(data => {
        this.pages = data.pages
        this.users = data.results
      })
    }
  },
  created() {},
  beforeMount() {
    this.refresh()
  },
  beforeDestroy() {}
}
</script>